<template>
<div class="container">
  <div class="title-class">
    <img src="@/assets/hykjlogo.png" style="text-align: left">
    <div class="title-content-class">周口华园科技有限公司</div>
  </div>
  <el-scrollbar height="91.667vh">
    <el-carousel height="91.944vh" :interval="5000" arrow="always">
      <el-carousel-item v-for="(item,index) in imageList"  :key="index">
        <div class="banner-class">
          <div class="banner-content-class">打造一流的集高精尖技术高层次人才、<br>高科技产业于一体的数字医疗健康教育产业高地</div>
          <img class="banner-img-class" :src="item">
          <img class="banner-mengceng-class" src="@/assets/mengceng.png">
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="company-class">
      <div class="company-title-class">企业简介</div>
      <div class="company-content-class">
        <img src="@/assets/qiye.png">
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;周口华园科技有限公司成立于2021年08月25日，注册资本： 500万(元)， 注册地位于河南省周口市川汇区八一路与太清路交叉口数字产业园3号楼1楼。经营范围包括一般项目：技术服务、技术开发、 技术咨询、技术交流、技术转让、技术推广；软件开发；科技中介服务；财务咨询；健康咨询服务（不含诊疗服务）；物联网应用服务；互联网数 据服务；信息技术咨询服务；物联网技术服务；数据处理和存储支持服务；人工智能硬件销售；体育用品及器材零售；日用家电零售；计算机软硬 件及辅助设备零售；通信设备销售<br>
        </div>
      </div>
      <div class="zaiti-class">
        <img class="zaiti-all-class" src="@/assets/all.png">
        <img class="zaiti-new-class" src="@/assets/new.png">
      </div>
    </div>
    <div class="project-class">
      <div class="project-title-class">华园控股及周口项目简介</div>
      <div class="project-content-class">
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;浙江清华长三角研究院是2003年在习近平同志亲自谋划、直接推动和大力支持
          下，由浙江省人民政府与清华大学联合组建的新型创新载体。华园控股是依托清华大学的人才、科技资源，由清华长三角研究院持股的高科技集团公
          司。 华园在周口市川汇区投资建设“华园数字医疗健康教育产业平台”包括数字健康教育平台、数字医疗平台、数字应急安全平台等，全面服务数字
          周口建设和相关产业发展。通过建设区域学生健康大数据平台、中小学及幼儿园近视防控示范区、学生营养健康示范区、学校食品安全示范区等一系
          列健康学校示范项目，有力推动周口健康教育事业发展。 华园携手川汇区，立足周口，辐射中原，打造一流的集高精尖技术高层次人才、高科技产
          业于一体的数字医疗健康教育产业高地，促进周口市儿童青少年健康成长，助力健康周口、数字周口建设走在中原前列，为推动川汇区和周口市经济
          社会发展贡献华园力量。</div>
        <img src="@/assets/project.png">
      </div>
    </div>
    <div class="relate-class">
      <img class="relate-mengceng-class" src="@/assets/relatemengceng.png">
      <div class="relate-lianxi-class">联系我们</div>
      <div class="relate-contact-class">Contact  Us</div>
      <div class="relate-title-class">
        <img src="@/assets/hykjlogo.png" style="text-align: left">
        <div class="title-content-class">周口华园科技有限公司</div>
      </div>
      <div class="relate-content-class">地点： 河南省周口市川汇区八一路与太清路交叉口数字产业园3号楼1楼 </div>
      <div class="relate-content-class" style="top: 15.37vh">电话： 0394-6121111</div>
      <div class="relate-content-class" style="top: 19.631vh">传真： 0394-6121111</div>
      <div class="relate-content-class" style="top: 23.892vh">邮箱： 18203940888@139.com</div>
      <div class="author-class">company版权所有         周口华园科技有限公司         网站备案号        <span class="author-span-class" @click="goTo('https://beian.miit.gov.cn/')">豫ICP备2023023954号-1</span> </div>
    </div>
  </el-scrollbar>
</div>
</template>

<script>
import {reactive,toRefs,onMounted,getCurrentInstance} from "vue"
export default {
name: "homeIndex",
  setup(){
  const {proxy} = getCurrentInstance()
    const data=reactive({
      imageList:[],
      publicPath: process.env.BASE_URL,
      getImage(){
        data.imageList=proxy.$config.iamgeList.map(res=>{
          return data.publicPath+res
        })
      },
      goTo(url){
        window.open(url)
      }
    })
    onMounted(()=>{
      data.getImage()
    })
    return{
      ...toRefs(data)
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: "SourceHanSans-Medium";
  color: rgba(51, 51, 51, 1);
  .title-class{
    width: 100vw;
    height: 8.333vh;
    opacity: 1;
    background: rgba(53, 107, 176, 1);
    position: relative;
    img{
      position: absolute;
      left: 4.167vw;
      top: 50%;
      transform: translateY(-50%);
      height: 3.148vh;
    }
    .title-content-class{
      position: absolute;
      left: 9.271vw;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
      /** 文本1 */
      font-size: 2.778vh;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 4.022vh;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;

    }
  }
  .banner-class{
    position: relative;
    height: 91.944vh;
    width: 100%;
    overflow: hidden;
    .banner-content-class{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 72.1875vw;
      height: 33.889vh;
      opacity: 1;
      font-size: 6.111vh;
      font-weight: 700;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      z-index: 2;
      line-height: 14.815vh;

    }
    .banner-img-class{
      position: absolute;
      top: 0px;
      left: 0px;
      height: 91.944vh;
      width: 100%;
    }
    .banner-mengceng-class{
      position: absolute;
      top: -0.1vh;
      left: -0.5%;
      height: 92.054vh;
      width: 101%;
      z-index: 1;
    }
  }
  .company-class{
    background-image: url("~@/assets/jianjiebg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100vw;
    height: 70vh;
    position: relative;
    z-index: 1;
    .company-title-class{
      position: absolute;
      left: 18.802vw;
      top: 4.352vh;
      height: 5.37vh;
      opacity: 1;
      font-size: 3.704vh;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 5.363vh;
      color: rgba(51, 51, 51, 1);
    }
    .company-content-class{
      position: absolute;
      left: 18.021vw;
      top: 13.241vh;
      display: flex;
      div{
        margin-left: 1.979vw;
        width: 29.542vw;
        height: 34.907vh;
        opacity: 1;
        font-size: 2.037vh;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 3.704vh;
        color: rgba(51, 51, 51, 1);
        text-align: justify;
        text-justify:inter-ideograph;
      }
      img{
        width: 27.701vw;
        height: 32.498vh;
        opacity: 1;
      }
    }
    .zaiti-class{
      background-image: url("~@/assets/zaiti.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 100vw;
      height: 17.222vh;
      bottom: 0px;
      position: absolute;
      z-index: 0;
    }
    .zaiti-all-class{
      position: absolute;
      left: 18.75vw;
      margin-top: 5.463vh;
      width: 21.71875vw;
      height: 6.944vh;
    }
    .zaiti-new-class{
      position: absolute;
      left: 46.09375vw;
      margin-top: 6.904vh;
      width: 35.104vw;
      height: 3.981vh;
    }
  }
  .project-class{
    width: 100vw;
    height: 82.5vh;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    position: relative;
    .project-title-class{
      position: absolute;
      left: 18.802vw;
      top: 4.352vh;
      height: 5.37vh;
      opacity: 1;
      font-size: 3.704vh;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 5.363vh;
      color: rgba(51, 51, 51, 1);
    }
    .project-content-class{
      position: absolute;
      left: 18.021vw;
      top: 13.241vh;
      display: flex;
      div{
        width: 29.542vw;
        height: 34.907vh;
        opacity: 1;
        font-size: 2.037vh;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 3.704vh;
        color: rgba(51, 51, 51, 1);
        text-align: justify;
        text-justify:inter-ideograph;
      }
      img{
        margin-left: 1.979vw;
        width: 33.333vw;
        height: 59.259vh;
        opacity: 1;
        margin-top: -6vh;
      }
    }
  }
  .relate-class{
    width: 100vw;
    height: 33.981vh;
    z-index: 0;
    background: rgba(53, 107, 176, 1);
    position: relative;

    .relate-mengceng-class{
      position: absolute;
      top: 0px;
      left: -0.5%;
      height: 33.981vh;
      width: 100vw;
      z-index: 1;
    }
    .relate-lianxi-class{
      position: absolute;
      left: 18.75vw;
      top: 3.704vh;
      width: 8.333vw;
      height: 5.37vh;
      opacity: 1;
      font-size: 3.704vh;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 5.363vh;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;
      z-index: 2;
    }
    .relate-contact-class{
      position: absolute;
      left: 18.75vw;
      top: 9.63vh;
      width: 10.104vw;
      height: 4.907vh;
      opacity: 1;
      font-size: 3.333vh;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 4.827vh;
      color: rgba(255, 255, 255, 0.6);
      text-align: left;
      vertical-align: top;
    }
    .relate-title-class{
      position: absolute;
      left: 38.854vw;
      top: 4.815vh;
      display: flex;
      img{
        height: 3.148vh;
        margin-right: 1.40625vw;
      }
      .title-content-class{
        width: 15.625vw;
        opacity: 1;
        font-size: 2.778vh;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 4.022vh;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;

      }
    }
    .relate-content-class{
      position: absolute;
      left: 38.854vw;
      top: 11.111vh;
      width: 29.635vw;
      height: 2.5vh;
      opacity: 1;
      font-size: 1.667vh;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 2.413vh;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;
    }
    .author-class{
      position: absolute;
      bottom: 0px;
      width: 100vw;
      height: 4.259vh;
      opacity: 1;
      background: rgba(46, 92, 153, 1);

      text-align: center;
      font-size: 1.667vh;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 4.259vh;
      color: rgba(255, 255, 255, 0.4);

    }
  }
  :deep().el-scrollbar{
    height: auto;
  }
}
.author-span-class{
  position: relative;
  z-index: 99999;
  cursor: pointer;
}
</style>
