import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index";

import "@/assets/css/mene-font.scss";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css"; // 原始css
const vue = createApp(App);
vue.use(router).use(ElementPlus).mount('#app')

const config = initConfig();
vue.config.globalProperties.$config = config.$Config;
