import {createRouter,createWebHashHistory} from "vue-router"
import login from "@/views/login"
import homeIndex from "@/views/homeIndex"
const routes=[
    {
        path:'/login',
        name:'login',
        component:login,
    },
    {
        path:'/',
        name:'homeIndex',
        component:homeIndex,
    },
];
const router=createRouter({
    history:createWebHashHistory(),
    routes
});
export default router;